import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    IconButton,
    useDisclosure,
    FormLabel,
    FormControl,
    Select,
    Button,
    VStack,
    Text,
    useToast, // Import useToast
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import axios from 'axios';

function EditTemplateDrawer({ item, onUpdate }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedTemplateName, setSelectedTemplateName] = useState("No Template");
    const [loading, setLoading] = useState(true);
    const toast = useToast(); // Initialize useToast

    const templates = [
        {
            id: 1,
            name: "Template 1",
            content: `Yth. Bapak/Ibu {nama-pelanggan},
  Terima kasih atas konfirmasi kunjungan dengan {nama-branch}.
  Anda memiliki jadwal layanan pada:
  
  Kode Booking Anda adalah: {kode-booking}
  
  Booking Detail:
  Tanggal: {tanggal}
  Perkiraan Jam: {jam-mulai} - {jam-berakhir}
  Nama Layanan : {nama-layanan}
  Lokasi : {alamat}
  
  Untuk detail status antrian atau batalkan perjanjian, Anda dapat klik tautan berikut:
  {booking-status}
  
  Untuk informasi lebih lanjut terkait layanan {nama-branch}, balas pesan ini atau silakan kunjungi link berikut ini:
  {link-branch}`,
        },
    ];

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const response = await axios.get(`https://api.pawarta.awandigital.id/api/get-user-template/${item.id}`);
                if (response.data.status) {
                    const template = response.data.data.template;
                    setSelectedTemplate(template.preview_template || "");
                    setSelectedTemplateName(template.nama_template || "No Template");
                } else {
                    setSelectedTemplate("");
                    setSelectedTemplateName("No Template");
                }
            } catch (error) {
                console.error("Error fetching template:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTemplate();
    }, [item.id]);

    const handleTemplateChange = (e) => {
        const selectedTemplateId = parseInt(e.target.value);
        const template = templates.find(t => t.id === selectedTemplateId);
        
        if (template) {
            setSelectedTemplate(template.content);
            setSelectedTemplateName(template.name);
        } else {
            setSelectedTemplate("");
            setSelectedTemplateName("No Template");
        }
    };

    const handleSave = async () => {
        try {
            const templateData = {
                user_id: item.id,
                template: selectedTemplate,
                template_name: selectedTemplateName,
            };

            const response = await axios.post('https://api.pawarta.awandigital.id/api/update-user-template', templateData);

            if (response.data.status) {
                console.log("Template updated successfully!");
                onUpdate({ ...item, template: selectedTemplate, templateName: selectedTemplateName }); // Update UI

                // Show success toast notification
                toast({
                    title: "Template Saved",
                    description: "Your template has been saved successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                console.error("Failed to update template");
            }

            onClose();
        } catch (error) {
            console.error("Error updating template:", error);
        }
    };

    return (
        <>
            <IconButton
                icon={<ChatIcon />}
                onClick={onOpen}
                bg="transparent"
                color="gray.500"
                _hover={{ bg: "green.100", color: "green.600" }}
                aria-label="Edit Template"
            />
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>Edit Template</DrawerHeader>
                    <DrawerBody>
                        {loading ? (
                            <Text>Loading...</Text>
                        ) : (
                            <>
                                <FormControl>
                                    <FormLabel>Select Template</FormLabel>
                                    <Select
                                        value={templates.find(t => t.content === selectedTemplate)?.id || ""}
                                        onChange={handleTemplateChange}
                                        placeholder="Select a template"
                                    >
                                        {templates.map((template) => (
                                            <option key={template.id} value={template.id}>
                                                {template.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <VStack spacing={4} mt={4}>
                                    <FormControl>
                                        <FormLabel>Template Name</FormLabel>
                                        <Text border="1px solid gray" p={2} borderRadius="md">
                                            {selectedTemplateName}
                                        </Text>
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Template Preview</FormLabel>
                                        <Text border="1px solid gray" p={2} borderRadius="md" whiteSpace="pre-wrap">
                                            {selectedTemplate || "No template selected"}
                                        </Text>
                                    </FormControl>
                                </VStack>
                            </>
                        )}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={handleSave}>
                            Save
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default EditTemplateDrawer;
